// Required during insertion if we have not set the keys it will add to the payload
export const insertDefaultPayLoad = {
  email: '',
  name: '',
  privileges: '',
  roles: [],
  supervisors: [],
  location: '',
  deleted: false,
  client_id: null,
}

export const getWorkers = `query getWorkers($query: WorkerQueryInput, $limit: Int!, $sortBy: WorkerSortByInput!){
    workers(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      email
      name
      privileges
      location 
      client_id {
        _id
      }
    }
  }`

export const getWorker = `query getWorker($query: WorkerQueryInput){
    worker(query: $query) {
      _id
      email
      name
      privileges
      roles
      supervisors {
        _id
      }
      locations {
        _id
        location
      }
      client_id {
        _id
      }
    }
  }`

const query = `query workers($query: WorkerQueryInput, $limit: Int, $sortBy: WorkerSortByInput){
    workers(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      email
      name
      supervisors {
        _id
      }
      locations {
        _id
        location
      }
      onlyDropdownOption
    }
  }`

// eslint-disable-next-line no-underscore-dangle
export const _getWorkers = `query WorkersData($input: WorkersDatum!){
    WorkersData(input: $input) {
        totalRecords
        workers {
          _id
          email
          name
          privileges
          roles
          supervisors
          locations
        }
        workersTotal {
          email
          name
          privileges
          rolesData {
            name
          }
          supervisorsData {
            name
          }
          locationsData {
            location
          }
        }
    }
  }`

export const getWorkersWithRoles = `query workers($query: WorkerQueryInput, $limit: Int, $sortBy: WorkerSortByInput){
  workers(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    roles
    supervisors {
      _id
    }
  }
}`

export const getWorkersWithSupervisors = `query workers($query: WorkerQueryInput){
  workers(query: $query) {
    _id
    name
    supervisors {
      _id
    }
  }
}`

export const workersSupervisedWithEvents = `query EventsForSupervisedWorkers($input: EventsForSupervisedWorker!){
  EventsForSupervisedWorkers(input: $input) {
    events {
      eventsData {
        _id
        name
        numEventsDone
        numEventsPending
      }
      totalEmployees
      totalNumEventsDoneCero
      totalNumEmployeesWithEvents
      totalNumEventsDone
    },
  }
}`

export const confirmationsForWorkers = `query ConfirmationsForWorkers($input: ConfirmationsForWorker!){
  ConfirmationsForWorkers(input: $input) {
    workersWithPagination {
      _id
      name
      locationsData {
        _id
        location
      }
      rolesData {
        numEventsRequired
      }
      confirmationsCount
    }
    workers {
      _id
      name
      locationsData {
        _id
        location
      }
      rolesData {
        numEventsRequired
      }
      confirmationsCount
    },
  }
}`

export const deleteOneWorker = `mutation deleteOneWorker($query: WorkerQueryInput!) {
    deleteOneWorker(query: $query) {
      _id
      email
      name
    }
  }
  `

export const addWorker = `mutation insertOneWorker($data: WorkerInsertInput!){
    insertOneWorker(data: $data) {
      _id
      email
      name
    }
  }`

export const updateWorker = `mutation updateOneWorker($query: WorkerQueryInput!, $data: WorkerUpdateInput!) {
    updateOneWorker(query: $query, set: $data) {
      _id
    }
  }
  `

export const getWorkersWithEmails = `query workers($query: WorkerQueryInput){
  workers(query: $query) {
    _id
    name
    email
  }
}`

export default query
